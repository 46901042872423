import React from "react";
import { Row, Col } from "antd";
import { useMediaQuery } from "react-responsive";

import CardImage from "../../components/CardImage";
import GirlImage from "../../assets/images/girl.png";
import Card from "../../assets/images/card2.png";
import Dice from "../../assets/images/dice2.png";
import Roshorambo from "../../assets/images/roshorambo2.png";
import xdragon from "../../assets/images/xdragon.jpg";

import "./style.css";

const Projects = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className="projects-container">
      <p
        className="section-title"
        style={{
          fontSize: isMobile ? "38px" : "48px",
          paddingBottom: isMobile ? "24px" : "40px",
          paddingTop: isMobile ? "54px" : "90px",
        }}
      >
        Ecosystem
      </p>

      <Row
        gutter={[{ xs: 0, sm: 32 }, 32]}
        style={{ minHeight: "250px", marginBottom: "32px" }}
        justify={"center"}
      >
        <Col xs={{ span: 20 }} md={{ span: 6 }}>
          <CardImage
            imageBg={GirlImage}
            bgImageClass="roshorambo-bg-image"
            title="Roshambo Web"
            description="Play the classic rock-paper-scissors game on the web where you can double your ICP"
            link="https://icp.dragoneyes.xyz/roshambo"
          />
        </Col>
        <Col xs={{ span: 20 }} md={{ span: 6 }}>
          <CardImage
            imageBg={Dice}
            bgImageClass="dice-bg-image"
            title="Dice & Mini Dice"
            description="The original Dragon Eyes dice game"
            link="https://dice.dragoneyes.xyz"
          />
        </Col>
        <Col xs={{ span: 20 }} md={{ span: 6 }}>
          <CardImage
            imageBg={xdragon}
            bgImageClass="dice-bg-image"
            title="XDRAGON"
            description="Hybrid Memecoin <> NFT on Arbitrum"
            link="https://x.dragoneyes.xyz"
          />
        </Col>
      </Row>

      <Row
        gutter={[{ xs: 0, sm: 32 }, 32]}
        style={{ minHeight: "250px" }}
        justify={"center"}
      >
        <Col xs={{ span: 20 }} md={{ span: 10 }}>
          <CardImage
            imageBg={Roshorambo}
            bgImageClass="roshorambo-web-bg-image"
            title="Roshambo on Solana Telegram app"
            description="Native Telegram mini app for Roshambo, available on Solana"
            link="https://t.me/dragoneyesxyz_bot/roshambo"
          />
        </Col>
        <Col xs={{ span: 20 }} md={{ span: 6 }}>
          <CardImage
            imageBg={Card}
            bgImageClass="dragon-bot-bg-image"
            title="Dragon Bot Telegram"
            description="Turn your Telegram group into a private decentralized casino in seconds! (it is super easy!)"
            link="https://tg.dragoneyes.xyz"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Projects;
