import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #17181d; /* Background color */
  overflow: hidden;
  z-index: -1;
`;

const Canvas = styled.canvas`
  display: block;
`;

const MatrixRain = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Determine font size based on screen width
    const isMobile = window.innerWidth <= 768; // Mobile breakpoint
    const fontSize = isMobile ? 12 : 18; // Smaller font for mobile
    const columns = Math.floor(canvas.width / fontSize);

    const emojis = ["🎲", "♠️", "♥️", "♦️", "♣️"]; // Casino emojis
    const drops = Array.from({ length: columns }, () => 1);

    const draw = () => {
      // Create translucent background for trail effect
      ctx.fillStyle = "rgba(23, 24, 29, 0.2)"; // Slight transparency for trail
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Set style for glowing grayscale emojis
      ctx.shadowBlur = 15; // Glow intensity
      ctx.shadowColor = "rgba(255, 165, 0, 0.05)"; // Orange hue for the glow
      ctx.fillStyle = "rgba(128, 128, 128, 0.7)"; // Grayscale with max 50% opacity
      ctx.font = `${fontSize}px monospace`;
      ctx.globalAlpha = 0.8; // Max opacity at 50%

      for (let i = 0; i < drops.length; i++) {
        const emoji = emojis[Math.floor(Math.random() * emojis.length)];
        const text = emoji;

        // Draw grayscale emoji
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);

        // Reset drop position when it reaches the bottom or randomly
        if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }

        // Move the drop to the next row
        drops[i]++;
      }
    };

    const interval = setInterval(draw, 100); // Adjust speed here

    return () => clearInterval(interval);
  }, []);

  return (
    <Wrapper>
      <Canvas ref={canvasRef} />
    </Wrapper>
  );
};

export default MatrixRain;
