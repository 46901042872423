import "./App.css";
import Navbar from "./components/Navbar";
import Landing from "./containers/Landing";
import Projects from "./containers/Projects";
import Features from "./containers/Features";
import Tokens from "./containers/Tokens";
import Roadmaps from "./containers/Roadmaps";
import Faq from "./containers/Faq";
import MatrixRain from "./Matrix";
import styled from "styled-components";
import Docs from "./containers/Docs";
import React, { useEffect, useRef } from "react";

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #17181d;
  z-index: 99;
`;

function App() {
  const videoRef = useRef(null);

  useEffect(() => {
    const playVideo = async () => {
      try {
        if (videoRef.current) {
          await videoRef.current.play();
        }
      } catch (error) {
        console.log("Video autoplay failed:", error);
      }
    };

    playVideo();
  }, []);

  return (
    <>
      <MatrixRain />
      <div
        style={{
          position: "relative",
          zIndex: 1,
          color: "white",
          padding: "0px",
        }}
      >
        <Navbar />
        <Wrapper>
          <VideoBackground ref={videoRef} muted playsInline autoPlay loop preload="auto">
            <source src="/herov3_small.mov" type="video/quicktime" />
            <source src="/herov3_small.webm" type="video/webm" />
            <source src="/herov3_small.mp4" type="video/mp4" />
          </VideoBackground>
          <Landing />
        </Wrapper>
        <Projects />
        <Features />
        <Tokens />
        <Roadmaps />
        <Faq />
        <Docs />
      </div>
    </>
  );
}

export default App;
