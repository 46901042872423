import React from "react";
import { Row, Col } from "antd";
import { useMediaQuery } from "react-responsive";
import CardImageTokens from "../../components/CardImageToken";

import EyesCardImage from "../../assets/images/eyescardimage.png";
import DragonCardImage from "../../assets/images/dragoncardimage.png";
import { useGlitch } from "react-powerglitch";
import "./style.css";
import TokenDiagram from "../../assets/images/token-diagram.png";

const Tokens = () => {
  const glitch = useGlitch();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div id="token" className="tokens-container" style={{ padding: isMobile ? "15px 35px" : "95px 95px" }}>
      <p
        className="section-title"
        style={{
          fontSize: isMobile ? "38px" : "48px",
        }}
      >
        Dual Token
      </p>
      <Row gutter={[{ xs: 0, sm: 50 }, 50]} justify={"center"}>
        <Col xs={{ span: 24 }} md={{ span: 8 }} style={{ height: "400px" }}>
          <CardImageTokens
            imageBg={DragonCardImage}
            bgImageClass="eyes-bg-image"
            tokenName="$EYES"
            tokenDescription="Reward token"
            description1="Earn $EYES rewards every time you play."
            description2="$EYES serves as the foundation of Proof of Gamble tokenomics system."
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }} style={{ textAlign: "center", margin: "20px 0" }}>
          <img
            src={TokenDiagram}
            alt="Token Diagram"
            style={{
              maxWidth: "100%",
              height: "auto",
              margin: "0 auto",
            }}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }} style={{ height: "400px", marginTop: isMobile ? "0px" : "100px" }}>
          <CardImageTokens
            imageBg={EyesCardImage}
            tokenName="$DRAGON"
            bgImageClass="dragon-bg-image"
            tokenDescription="Governance and Yield bearing token"
            textPosition="bottom"
            description1=" 100% of profits from all games are distributed to $DRAGON holders via time-weighted staking mechanism."
            description2=""
          />
        </Col>
      </Row>
      <p
        className="section-title"
        style={{
          fontSize: isMobile ? "18px" : "28px",
        }}
      >
        <br />
      </p>
      <p
        className="section-title"
        style={{
          fontSize: isMobile ? "22px" : "28px",
        }}
      >
        <span ref={glitch.ref}>Mine $DRAGON by burning $EYES</span>
      </p>
    </div>
  );
};

export default Tokens;
