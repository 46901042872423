import React from "react";

import "./style.css";

const CardImage = ({ imageBg, bgImageClass, title, description, link }) => {
  return (
    <div className="card-image-container">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <div className="card-image-overlay"></div>
        <img
          src={imageBg}
          alt="background"
          className={`card-image-background ${bgImageClass}`}
        />
        <div className="card-image-text">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </a>
    </div>
  );
};

export default CardImage;
