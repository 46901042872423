import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useMediaQuery } from "react-responsive";
import CountUp from "react-countup";
import { useGlitch } from "react-powerglitch";
import axios from "axios";
import "./style.css";

const Landing = () => {
  const glitch = useGlitch();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [totalGamesPlayed, setTotalPlayed] = useState(500);
  const [totalReward, setTotalReward] = useState(412940);
  const [totalPlayer, setTotalPlayer] = useState(1900);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api.dragoneyes.xyz/fetchLastCoreStats"
      );
      const data = response.data;
      setTotalPlayed(
        parseFloat(data.played).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      );
      setTotalReward(
        parseFloat(data.totalReward).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
      setTotalPlayer(
        parseFloat(data.players + 2000).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const timer = setInterval(() => {
      fetchData();
    }, 30000); // Update setiap menit

    return () => clearInterval(timer);
  }, []);

  return (
    <div
      id="landing"
      className="landing-container"
      style={{ paddingTop: isMobile ? "80px" : "150px" }}
    >
      <div className="landing-content">
        <Row>
          <Col
            xs={{ span: 22, offset: 1 }}
            md={{ span: 18, offset: 3 }}
            lg={{ span: 12, offset: 6 }}
          >
            <div className="hero-container">
              <p
                className="hero-title"
                style={{ fontSize: isMobile ? "36px" : "64px" }}
              >
                <span ref={glitch.ref}>Dragon Eyes</span>
              </p>
              <p
                className="hero-description"
                style={{ fontSize: isMobile ? "20px" : "24px" }}
              >
                <span ref={glitch.ref}>
                  The world’s first decentralized casino powered by Proof of
                  Gamble
                </span>
              </p>
            </div>
          </Col>
        </Row>

        <Row
          justify="space-around"
          align="middle"
          style={{ paddingTop: isMobile ? "150px" : "120px" }}
        >
          <Col xs={{ span: 20 }} md={{ span: 6 }} lg={{ span: 4 }}>
            <div className="achivement-container-title">
              {/*<CountUp start={0} end={500} suffix="K+">
                {({ countUpRef }) => (
                  <span
                    ref={countUpRef}
                    className="achivement-title"
                    style={{
                      fontSize: isMobile ? "32px" : "48px",
                      textAlign: "center",
                    }}
                  />
                )}
              </CountUp> */}

              <div
                className="achivement-title"
                style={{ fontSize: isMobile ? "32px" : "48px" }}
              >
                {totalGamesPlayed}
              </div>

              <p className="achivement-description">Total Games Played</p>
            </div>
          </Col>
          <Col xs={{ span: 20 }} md={{ span: 6 }} lg={{ span: 4 }}>
            <div className="achivement-container-title">
              {/* <CountUp start={0} end={412940} prefix="$">
                {({ countUpRef }) => (
                  <span
                    ref={countUpRef}
                    className="achivement-title"
                    style={{
                      fontSize: isMobile ? "32px" : "48px",
                      textAlign: "center",
                    }}
                  />
                )}
              </CountUp> */}

              <div
                className="achivement-title"
                style={{ fontSize: isMobile ? "32px" : "48px" }}
              >
                ${totalReward}
              </div>

              <p className="achivement-description">Reward Paid</p>
            </div>
          </Col>
          <Col xs={{ span: 20 }} md={{ span: 6 }} lg={{ span: 4 }}>
            <div className="achivement-container-title">
              {/*<CountUp start={0} end={1900} suffix="+">
                {({ countUpRef }) => (
                  <span
                    ref={countUpRef}
                    className="achivement-title"
                    style={{
                      fontSize: isMobile ? "32px" : "48px",
                      textAlign: "center",
                    }}
                  />
                )}
              </CountUp>*/}

              <div
                className="achivement-title"
                style={{ fontSize: isMobile ? "32px" : "48px" }}
              >
                {totalPlayer}
              </div>

              <p className="achivement-description">Total Users</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Landing;
