import React from "react";
import { Row, Col, Steps } from "antd";
import { useMediaQuery } from "react-responsive";

import "./style.css";

const Roadmaps = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div
      id="roadmap"
      className="roadmaps-container"
      style={{ padding: isMobile ? "100px 35px" : "95px 95px" }}
    >
      <p
        className="section-title"
        style={{
          fontSize: isMobile ? "38px" : "48px",
          // paddingTop: isMobile ? "100px" : "-20px",
        }}
      >
        Roadmap
      </p>

      <Row justify="center">
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <div className="custom-steps-container">
            <Steps
              direction="vertical"
              progressDot
              current={3}
              className="custom-steps"
            >
              <Steps.Step
                title={
                  <span
                    className="step-left"
                    style={{ fontSize: isMobile ? "20px" : "32px" }}
                  >
                    Active Games Launched
                  </span>
                }
                description={
                  <span className="description-left">
                    Initial launch of Roshambo, Dice, and Mini Dice games
                  </span>
                }
              />
              <Steps.Step
                title={
                  <span
                    className="step-left"
                    style={{ fontSize: isMobile ? "24px" : "32px" }}
                  >
                    Reward Token Launch ($EYES)
                  </span>
                }
                description={
                  <span className="description-left">
                    Introduction of $EYES as a reward for all players
                  </span>
                }
              />
              <Steps.Step
                title={
                  <span
                    className="step-right"
                    style={{ fontSize: isMobile ? "24px" : "32px" }}
                  >
                    Expansion to Multichain
                  </span>
                }
                description={
                  <span className="description-left">
                    Enabled multichain gameplay with ChainFusion, Solana in
                    Telegram ecosystem
                  </span>
                }
              />

              <Steps.Step
                disabled
                title={
                  <span
                    className="step-left disable"
                    style={{ fontSize: isMobile ? "24px" : "32px" }}
                  >
                    $DRAGON SNS Sale & TGE
                  </span>
                }
                description={
                  <span className="description-left disable">
                    Launch of $DRAGON native token, leading to DAO governance
                  </span>
                }
              />

              <Steps.Step
                disabled
                title={
                  <span
                    className="step-right disable"
                    style={{ fontSize: isMobile ? "24px" : "32px" }}
                  >
                    $DRAGON mining by Proof of Gamble
                  </span>
                }
                description={
                  <span className="description-right disable">
                    Players can mine $DRAGON by initiate Dragon miners and burn
                    $EYES
                  </span>
                }
              />

              <Steps.Step
                disabled
                title={
                  <span
                    className="step-left disable"
                    style={{ fontSize: isMobile ? "24px" : "32px" }}
                  >
                    Transition to DAO via SNS
                  </span>
                }
                description={
                  <span className="description-left disable">
                    House decentralization and empowering players to make
                    decision and shape the future of Dragon Eyes
                  </span>
                }
              />

              <Steps.Step
                disabled
                title={
                  <span
                    className="step-right disable"
                    style={{ fontSize: isMobile ? "24px" : "32px" }}
                  >
                    $DRAGON staking to stream house profit
                  </span>
                }
                description={
                  <span className="description-right disable">
                    100% fees from all games rewarded to $DRAGON stakers based
                    on time-weighted mechanism
                  </span>
                }
              />
            </Steps>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Roadmaps;
