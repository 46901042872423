import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useMediaQuery } from "react-responsive";

import DragonIcon from "../../assets/icon/dragoneyesicon.png";
import XLogo from "../../assets/icon/xlogo.png";
import TelegramLogo from "../../assets/icon/telegramlogo.png";
import CollapseIcon from "../../assets/icon/collapseicon.png";

import "./style.css";

const Navbar = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [bgOpacity, setBgOpacity] = useState(0);
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const maxScroll = 200;
      const opacity = Math.min(scrollTop / maxScroll, 1);
      setBgOpacity(opacity);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuVisible((prev) => !prev);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      setMenuVisible(false); // Tutup menu setelah scroll
    }
  };

  // https://x.com/dragoneyesxyz
  // https://t.me/HouseOfXDragon

  const redirect = (key) => {
    switch (key) {
      case "twitter":
        window.open(
          "https://x.com/dragoneyesxyz",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "telegram":
        window.open(
          "https://t.me/HouseOfXDragon",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "docs":
        window.open(
          "https://docs.dragoneyes.xyz",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="navbar-container"
      style={{ backgroundColor: `rgba(22, 22, 22, ${bgOpacity})` }}
    >
      <Row justify="space-between" align="middle">
        <Col xs={{ span: 3 }} sm={{ span: 3 }}>
          <img
            alt="icon"
            className="clickable"
            src={DragonIcon}
            width={isMobile ? 32 : 56}
            style={{ paddingLeft: isMobile ? "10px" : "20px" }}
            onClick={() => scrollToSection("landing")}
          />
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 8 }}>
          <div className="navbar-button-container">
            <span
              className="nav-button clickable"
              onClick={() => scrollToSection("feature")}
            >
              Features
            </span>
            <span
              className="nav-button clickable"
              onClick={() => scrollToSection("token")}
            >
              Tokens
            </span>
            <span
              className="nav-button clickable"
              onClick={() => scrollToSection("roadmap")}
            >
              Roadmap
            </span>
            <span
              className="nav-button clickable"
              onClick={() => redirect("docs")}
            >
              Docs
            </span>
          </div>
        </Col>
        <Col xs={{ span: 17 }} sm={{ span: 3 }}>
          <div
            className="social-container"
            style={{
              justifyContent: isMobile ? "center" : "flex-end",
              padding: isMobile ? "0px 10px" : "0px 30px",
            }}
          >
            <img
              alt="icon"
              className="clickable"
              src={TelegramLogo}
              width={24}
              onClick={() => redirect("telegram")}
            />
            <img
              alt="icon"
              className="clickable"
              src={XLogo}
              width={20}
              onClick={() => redirect("twitter")}
            />
          </div>
        </Col>
        <Col xs={{ span: 3 }} sm={{ span: 0 }}>
          <div
            className="social-container"
            style={{
              justifyContent: isMobile ? "center" : "flex-end",
              padding: isMobile ? "0px 10px" : "0px 30px",
            }}
          >
            <img
              alt="icon"
              className="clickable"
              src={CollapseIcon}
              width={18}
              onClick={toggleMenu}
            />
          </div>
        </Col>
      </Row>

      {menuVisible && (
        <div className="mobile-navbar-menu">
          <span
            className="nav-button clickable"
            onClick={() => scrollToSection("feature")}
          >
            Features
          </span>
          <span
            className="nav-button clickable"
            onClick={() => scrollToSection("token")}
          >
            Tokens
          </span>
          <span
            className="nav-button clickable"
            onClick={() => scrollToSection("roadmap")}
          >
            Roadmap
          </span>
          <span
            className="nav-button clickable"
            onClick={() => redirect("docs")}
          >
            Docs
          </span>
        </div>
      )}
    </div>
  );
};

export default Navbar;
