import React from "react";
import { Row, Col } from "antd";
import { useMediaQuery } from "react-responsive";

import "./style.css";

const Features = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div
      id="feature"
      className="features-container"
      style={{ padding: isMobile ? "75px 35px" : "95px 95px" }}
    >
      <p
        className="section-title"
        style={{
          fontSize: isMobile ? "38px" : "48px",
        }}
      >
        Features
      </p>
      <Row
        className={`features-inner-container ${
          !isMobile ? "with-bg-image" : ""
        }`}
        style={{ padding: isMobile ? "35px 35px" : "95px 95px" }}
        justify="space-between"
      >
        <Col xs={{ span: 24 }} md={{ span: 6 }}>
          <p className="feature-item-title">Multichain Capability</p>
          <p className="feature-item-description">
            Seamless gaming across multiple blockchains, powered by ChainFusion.
          </p>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 6 }}>
          <p className="feature-item-title">Provably Fair</p>
          <p className="feature-item-description">
            Guaranteed fairness with onchain randomness, creating transparent
            gaming experiences.
          </p>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 6 }}>
          <p className="feature-item-title">Fully Decentralized</p>
          <p className="feature-item-description">
            Built on Internet Computer, ensuring full decentralization and
            security.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Features;
