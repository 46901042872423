import React from "react";
import { Row, Col, Collapse } from "antd";
import { useMediaQuery } from "react-responsive";

import "./style.css";

const Faq = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const items = [
    {
      key: "1",
      label: "How to get $EYES?",
      children: `Every time you play in any of the games in Dragon Eyes ecosystem, you will get $EYES as reward. Hence, it's called Proof of Gamble.`,
    },
    {
      key: "2",
      label: "How do you get $DRAGON holders earn fees from?",
      children: `The Dragon house charges fees from all the games that runs on Dragon Eyes ecosystem. After $DRAGON TGE, 100% fees will be distributed to $DRAGON holders.`,
    },
    {
      key: "3",
      label:
        "The games has different currencies from different chain. In what currency the fees will be distributed in?",
      children: `You will receive fees from each currency the games are. There will also an option to auto convert the fees to get more $DRAGON.`,
    },
    {
      key: "4",
      label: "How to get $DRAGON?",
      children: (
        <div className="faq-content-children">
          <p>
            The $DRAGON TGE hasn't done yet. After TGE, you will have several
            options:{" "}
          </p>
          <p> 1. Buy on the SNS Sale (limited time)</p>
          <p> 2. Buy on the DEX/Exchange</p>
          <p>3. Burn $EYES you earn from the games you have played`</p>
        </div>
      ),
    },
  ];

  return (
    <div
      id="faq"
      className="faq-container"
      style={{ padding: isMobile ? "100px 35px" : "95px 95px" }}
    >
      <p
        className="section-title"
        style={{
          fontSize: isMobile ? "38px" : "48px",
          // paddingTop: isMobile ? "100px" : "-20px",
        }}
      >
        Faq
      </p>

      <Row justify="center">
        <Col xs={{ span: 24 }} md={{ span: 18 }} xl={{ span: 12 }}>
          <Collapse
            className="faq-collapse"
            expandIconPosition="end"
            items={items}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Faq;
