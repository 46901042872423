import React from "react";

import "./style.css";

const CardImageToken = ({
  imageBg,
  bgImageClass,
  tokenName,
  tokenDescription,
  description1,
  description2,
  textPosition,
}) => {
  return (
    <div className="card-image-container">
      <div className="card-image-overlay"></div>
      <img
        src={imageBg}
        alt="background"
        className={`card-image-background ${bgImageClass}`}
      />
      <div
        className={`card-image-text ${
          textPosition === "bottom" ? "text-bottom" : ""
        }`}
      >
        <h1 className="token-name">{tokenName}</h1>
        <p className="token-description">{tokenDescription}</p>
        <p className="token-details">{description1}</p>
        <p className="token-details">{description2}</p>
      </div>
    </div>
  );
};

export default CardImageToken;
